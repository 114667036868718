import React from 'react';
import { IModalBody } from '../../../types/properties';
import style from '../../modal.scss';
import * as helper from '../../helper/html-helper'
export const ModalBody = (props: IModalBody) => {
    return (
        <div className={style.modal_body}>
            <div className={style.main_image_container}>
                <div className={style.main_image}>
                    <img src={helper.parserImageUrl(props.model.imageUrl, props.cdnHost)}></img>
                </div>
            </div>
            <div className={style.details_container}>
                <div className={style.description}>
                    {helper.decodeHTML(props.localization.itemAddedToBasket)}
                </div>
                <div className={style.article_name}>
                    {props.model.title}
                </div>
                {(props.model.colorName && props.model.colorName !== '') &&
                    (<div className={style.color}>
                        {helper.decodeHTML(props.localization.color)}: {props.model.colorName}
                    </div>)
                }

                {(props.model.size && props.model.size !== '') &&
                    (<div className={style.size}>
                        {helper.decodeHTML(props.localization.size)}: {props.model.size}
                    </div>)}

                {(props.model.modelLabel && props.model.modelLabel !== '') &&
                    (<div className={style.model}>
                        {helper.decodeHTML(props.localization.model)}: {props.model.modelLabel}
                    </div>)}

                {(props.model && !!props.model.quantity) &&
                    (<div className={style.quantity}>
                        {helper.decodeHTML(props.localization.quantity)}: {props.model.quantity}
                    </div>)}
            </div>
        </div>);
}
