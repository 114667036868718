import React from 'react';
import { IModalBodyForLC } from '../../../types/properties';
import style from '../../modal.scss';
import * as helper from '../../helper/html-helper'
export const ModalBodyForLC = (props: IModalBodyForLC) => {
    const isNewSizeVariant = props.model.sizesWithPrice && props.model.sizesWithPrice.length > 0;

    const showSizeLabel = isNewSizeVariant || (props.model.size && props.model.size.length > 0);
    const sizeLabel = isNewSizeVariant ? props.model.sizesWithPrice.map(size => size.label).join(', ') : props.model.size.join(', ');

                
    const showQuantityLabel = isNewSizeVariant || (props.model.quantity && props.model.quantity.length > 0);
    const quantityLabel = isNewSizeVariant ?
        props.model.sizesWithPrice.map(size => size.quantity).reduce((sum: number, current: number) => sum + current, 0) :
        props.model.quantity.reduce((sum: number, current: number) => sum + current, 0)
    return (
        <div className={style.modal_body}>
            <div className={style.main_image_container}>
                <div className={style.main_image}>
                    <img src={helper.parserImageUrl(props.model.imageUrl, props.cdnHost)}></img>
                </div>
            </div>
            <div className={style.details_container}>
                <div className={style.description}>
                    {helper.decodeHTML(props.localization.personalisationAddToBasket)}
                </div>
                <div className={style.article_name}>
                    {props.model.title}
                </div>
                {(props.model.colorName && props.model.colorName !== '') &&
                    (<div className={style.color}>
                        {helper.decodeHTML(props.localization.color)}: {props.model.colorName}
                    </div>)
                }
                {showSizeLabel &&
                    (<div className={style.size}>
                        {helper.decodeHTML(props.localization.size)}: {sizeLabel}
                    </div>)}

                {(props.model.modelLabel && props.model.modelLabel !== '') &&
                    (<div className={style.model}>
                        {helper.decodeHTML(props.localization.model)}: {props.model.modelLabel}
                    </div>)}

                {showQuantityLabel &&
                    (<div className={style.quantity}>
                        {helper.decodeHTML(props.localization.quantity)}:
                        {' ' + quantityLabel}
                    </div>)}
            </div>
        </div>);
}
